import Axios from 'axios';

const stage = 'prod';
const region = 'NA'.toLowerCase();
const baseURL =
  stage.toLowerCase() !== 'prod'
    ? `https://api.${region}.${stage}.audits-procurement.amazon.dev`
    : `https://api.${region}.audits-procurement.amazon.dev`;

export const axios = Axios.create({
  baseURL: baseURL,
});
